import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

export default function Typography() {
  return (
    <div className="section section-typo">
      <Container>
        <h3 className="title">Services</h3>
        <div id="typography">
          <Row>
            <Col md="12">
              <div className="typography-line">
                <span>
                  <i>yourname</i>
                  <strong>@mayet.me</strong>
                </span>
                <blockquote>
                  <p className="blockquote blockquote-info">
                    Personalised email addresses, moving away from generic email
                    providers such as Google and Microsoft.
                    <Button
                      className="btn-simple float-right"
                      color="link"
                      to="email"
                      tag={Link}
                    >
                      More Information
                    </Button>
                  </p>
                </blockquote>
              </div>
              <div className="typography-line">
                <span>
                  <i>yourname</i>
                  <strong>.mayet.me</strong>
                </span>
                <blockquote>
                  <p className="blockquote blockquote-info">
                    Web hosting with an identity. Personal and Business websites
                    are welcomed. Host a curriculum vitae, blog or online store.
                    <Button
                      className="btn-simple float-right"
                      color="link"
                      to="hosting"
                      tag={Link}
                    >
                      More Information
                    </Button>
                  </p>
                </blockquote>
              </div>
            </Col>
          </Row>
        </div>
        <div className="space-50" />
      </Container>
    </div>
  );
}
