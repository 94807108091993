import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

export default function HostingTypography() {
  return (
    <div className="section section-typo">
      <Container>
        <h3 className="title">Hosting</h3>
        <div id="typography">
          <Row>
            <Col md="12">
              <div className="typography-line">
                <span>
                  <strong>About</strong>
                </span>
                <blockquote>
                  <p className="blockquote blockquote-info">
                    Websites such as personal portfolios or even business
                    websites can be hosted here. Users will be granted ftp and
                    shell access to control there hosted website.
                  </p>
                </blockquote>
              </div>
              <div className="typography-line">
                <span>
                  <strong>Setup</strong>
                </span>
                <blockquote>
                  <p className="blockquote blockquote-info">
                    Due to the more dynamic nature of domains, and their uses,
                    please contact us for any help or information needed to use
                    the Mayet.me domains.
                  </p>
                </blockquote>
              </div>
            </Col>
          </Row>
        </div>
        <div className="space-50" />
      </Container>
    </div>
  );
}
