import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="4">
            <h4 className="title">© 2021 Mayet.me</h4>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
