import React from "react";

// reactstrap components
import { Container } from "reactstrap";

export default function EmailPageHeader() {
  return (
    <div className="page-header header-filter">
      <Container>
        <div className="content-center brand">
          <h1 className="h1-seo">@Mayet•Me</h1>
          <h3 className="d-none d-sm-block">
            Personalised email addresses exclusively for Mayets
          </h3>
        </div>
      </Container>
    </div>
  );
}
