import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

export default function EmailTypography() {
  return (
    <div className="section section-typo">
      <Container>
        <h3 className="title">Email</h3>
        <div id="typography">
          <Row>
            <Col md="12">
              <div className="typography-line">
                <span>
                  <strong>About</strong>
                </span>
                <blockquote>
                  <p className="blockquote blockquote-info">
                    Professional email address that can be used as a personal or
                    work alias. Eliminates the need for generic or contract
                    bound email addresses.
                  </p>
                </blockquote>
              </div>
              <div className="typography-line">
                <span>
                  <strong>Setup</strong>
                </span>
                <blockquote>
                  <p className="blockquote blockquote-info">
                    <h5>Useful Links</h5>
                    Email Configuration
                    <Button
                      className="btn-simple"
                      color="link"
                      href="https://mailboxes.dreamhost.com/"
                    >
                      mailboxes.mayet.me
                    </Button>
                    <br />
                    Mailbox
                    <Button
                      className="btn-simple"
                      color="link"
                      href="https://webmail.dreamhost.com/"
                    >
                      webmail.mayet.me
                    </Button>
                    <br />
                    <br />
                    <small>
                      NB: Email forwarding or aliases are available options as
                      well.
                    </small>
                  </p>
                </blockquote>
              </div>
            </Col>
          </Row>
        </div>
        <div className="space-50" />
      </Container>
    </div>
  );
}
