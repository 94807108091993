import React from "react";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import HostingPageHeader from "components/PageHeader/HostingPageHeader.js";
import Footer from "components/Footer/Footer.js";

// sections for this page/view
import HostingTypography from "views/IndexSections/HostingTypography.js";
import Download from "views/IndexSections/Download.js";

export default function Index() {
  React.useEffect(() => {
    document.body.classList.toggle("index-page");
    // Specify how to clean up after this effect:
    return function cleanup() {
      document.body.classList.toggle("index-page");
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <HostingPageHeader />
        <div className="main">
          <HostingTypography />
          <Download />
        </div>
        <Footer />
      </div>
    </>
  );
}
