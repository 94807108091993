import React from "react";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

export default function Download() {
  return (
    <div
      className="section section-download"
      data-background-color="black"
      id="download-section"
    >
      <Container>
        <Row className="justify-content-md-center">
          <Col className="text-center" lg="8" md="12">
            <h2 className="title">
              Do you want a Mayet.Me Email or Subdomain?
            </h2>
            <h4 className="description">
              If you are a Mayet, it can be yours for FREE. Hit the button below
              to contact us!
            </h4>
          </Col>
          <Col className="text-center" lg="8" md="12">
            <Button
              className="btn-round"
              color="info"
              href="mailto:info@mayet.me"
              role="button"
              size="lg"
            >
              Contact Us
            </Button>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <br />
      </Container>
    </div>
  );
}
